// @ts-nocheck
// PUSH_KEY is nothing but vapid public key
import { useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActiveScenario } from '../state/slices/appSlice';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
// import utils from '../utils/Axios';
import useScenario from './ScenarioManager/useScenario';

const config = {
  PUSH_KEY:
    'BC2puH6F4zC94efzRdnsMTf9tDNKOfpmsuzZjuICN_y4P0jBi2Q1X65OHYTBgbVBYmp8YQHNGllCu9o1xfIwrBs',
};

const usePushNotifications = () => {
  // const { app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchScenarioList } = useScenario();
  const urlB64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      // eslint-disable-next-line no-useless-escape
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };
  const getBrowserName = () => {
    // gets the type of browser
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return 'opera';
    }
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'chrome';
    }
    if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'cafari';
    }
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'firefox';
    }
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      !!document.documentMode === true
    ) {
      return 'ie'; // crap
    }
    return 'unknown';
  };
  const subscribe = async () => {
    // Use serviceWorker.ready to ensure that you can subscribe for push
    navigator.serviceWorker.ready.then(async (serviceWorkerRegistration) => {
      if (Notification.permission !== 'granted') {
        const subscription =
          await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(config.PUSH_KEY),
          });
        const token = await localStorage.getItem('token');

        axios
          .post(
            API_ENDPOINTS.BASE_URL + API_ENDPOINTS.PUSH_NOTIFICATION.SUBSCRIBE,
            JSON.stringify({
              group: 'AtomWebPush', // topic
              status_type: 'subscribe',
              browser: getBrowserName(),
              subscription,
            }),
            {
              headers: {
                'content-type': 'text/html',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(
            () => {},
            () => {}
          );
      }
    });
  };
  const getPageUrlByModuleSubModuleName = (module) => {
    switch (module) {
      case 'ExploratoryAnalysis':
        return '/exploratoryAnalysis';
      case 'OutlierAnalysis':
        return '/outlierAnalysis';
      case 'TunningAnalysis':
        return '/tuningAnalysis/tuningData';
      case 'Consolidated':
        return '/tuningAnalysis/consolidatedView';
      case 'Sampling':
        return '/sampling/atlbtlAnalysis';
      case 'SamplingDownload':
        return '/sampling/sampleGeneration';
      case 'ImpactAnalysis':
        return '/impactAnalysis';
      case 'WhatIf':
        return '/whatIf';
      default:
        return '/';
    }
  };
  const redirectForNotification = useCallback(
    (notificationData, scenarioData) => {
      if (scenarioData.length) {
        const notificationProject = scenarioData.find(
          (prj) => prj.ScenarioId === notificationData.scenarioId
        );
        dispatch(setActiveScenario(notificationProject));
        const url = getPageUrlByModuleSubModuleName(notificationData.module);
        const params = new URLSearchParams(notificationData).toString();
        navigate(`${url}?${params}`);
      }
    },
    []
  );

  const handleNotificationClick = useCallback(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        // event is a MessageEvent object
        console.log(
          `The service worker sent me a message: ${JSON.stringify(event.data)}`
        );
        fetchScenarioList().then((res) => {
          if (res) {
            redirectForNotification(event.data, res?.data);
          }
        });
      });
    }
  }, [redirectForNotification]);

  return {
    subscribe,
    urlB64ToUint8Array,
    handleNotificationClick,
  };
};

export default usePushNotifications;
