export const API_ENDPOINTS = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  //  ---------------------AUTH------------------------------ //
  AUTH: {
    LOGIN: '/auth/loginv2/',
    REFRESH_TOKEN: '/auth/token/refresh',
    RESET_PASSWORD: '/auth/request_reset_email/',
    CHANGE_PASSWORD: '/auth/password_reset_complete/',
  },
  FOLDERS: {
    LIST: '/data/folder',
    EXECUTE: '/data/extract',
    REVIEW: '/data/review',
  },
  SCENARIO: {
    LIST: '/scenario_manager/scenarios/',
    CREATE_SCENARIO: '/data/scenario',
  },
  PUSH_NOTIFICATION: {
    SUBSCRIBE: '/data/webpush',
  },
  DATASET: {
    LIST: '/data/allsets',
    DATA: '/data/',
    THRESHOLD_DATA: '/data/threshold/',
    COLUMN_DETAIL: '/data/column-details',
  },
  DATA_OVERVIEW: {
    TUNABLE_PARAMETER: '/data/tune-parameter',
    AUDIT_TRAIL: '/data/audit',
    CLONE_DATASET: '/scenario_manager/scenarios/clone-tune-dataset/',
    SAVE_LOGIC: '/data/save-logic',
  },
  DATA_INGESTION: {
    UPLOAD_S3: '/data/uploads3',
    UPLOAD_SIGNED: '/data/uploadsigned',
    UPLOAD_COMPLETED: '/data/uploadcomplete',
    DELETE_SCENARIO: '/data/scenario',
    EDIT_SCENARIO: '/data/scenario',
    UPLOAD_THRESHOLD_FILE: '/data/import-data',
    DELETE_DATASET: '/scenario_manager/scenarios/delete-dataset/',
    DELETE_THRESHOLD: '/data/threshold/',
    CODE_VALIDATION: '/data/scenario/query-code',
    EXECUTE: '/data/etl',
    UPDATE_THRESHOLD: '/data/cat-threshold',
    FREQ_LIST: '/data/config/freq',
    CREATE_FREQ: '/data/config/freq',
    UPDATE_FREQ: '/data/config/freq',
    LOOKBACK_LIST: '/data/config/lookback',
    CREATE_LB: '/data/config/lookback',
    UPDATE_LB: '/data/config/lookback',
    DELETE_FREQ: '/data/config/freq',
    DELETE_LB: '/data/config/lookback',
  },
  DM: {
    ALL_SECTION: '/ingestion/internal-schema/all/',
    INITIAL_FILE_UPLOAD: '/ingestion/upload/start',
    UPLOAD_CHUNK: '/ingestion/upload/chunk',
    UPLOAD_COMPLETED: '/ingestion/upload/finish',
    GET_SOURCE_COLUMN: '/ingestion/columns/source',
    GET_DESTINATION_COLUMN: '/ingestion/columns/internal-schema',
    USER_SCHEMA: '/ingestion/user-schema',
    SCHEMA_MAPPING: '/ingestion/map-schema',
    ALL_SCHEMA_MAPPING: '/ingestion/user-schema/all/',
    INGESTION_STREAM: '/dataIngestion/ingestionStream',
    GET_PIPELINE_DATA: '/magicETL/single-pipeline/',
    ADD_BLOCK: '/magicETL/block',
    BLOCK_TEMPLATE: '/magicETL/template',
    FINALIZE_SCHEMA: '/ingestion/finalize-schema',
    RUN_BLOCK: '/magicETL/blockRun',
    CHECK_SCHEMA: '/ingestion/check-user-schema-existance',
    PIPELINE_RUN: '/magicETL/pipeline/run',
    ABORT: '/magicETL/blockRun/abort',
    LOGS: '/magicETL/blockRun/log',
    BLOCK_OUTPUT: '/magicETL/blockRun/output',
    GET_ALL_RUNS: '/magicETL/pipeline/get-all-run/',
    ADD_DATA: '/ingestion/add-data',
    SCHEDULE_PIPELINE: '/magicETL/pipeline/schedule/',
    GET_ALL_SCHEDULES: '/magicETL/pipeline/get-all-schedules/',
    GET_PIPELINE_RUN_DETAILS: '/magicETL/pipeline/runs/',
    SCHEMA: '/ingestion/schemas/',
    INGESTION_STATUS: '/ingestion/ingestion-status',
  },
  TASK: {
    LIST: '/data/tasks',
    VIEW: '/data/tasks/',
    DELETE: '/data/tasks/',
  },
  EDA: {
    TYPE: '/preliminary/column/type',
    SUMMARY_STAT: '/preliminary/summary-stats',
    OVERALL_STAT: '/preliminary/summary-stats/overall',
    BIVARIATE_ANALYSIS: '/preliminary/bivariate/analysis/',
  },
  OUTLIER_ANALYSIS: {
    OUTLIER_CALL: '/outlier/analysis',
    OUTLIER_FREEZE: '/outlier/freeze',
  },
  TUNING_DATA_ANALYSIS: {
    TDA_CALL: '/tunning/analysis',
    FREEZE: '/tunning/freeze',
    CONSOLIDATEDVIEW: '/tunning/consolidated-view',
  },
  SAMPLING: {
    FINAL_TUNABLE: '/data/tunned/',
    ATL_BTL: '/sampling/atl-btl/',
    FREEZE_BTL: '/sampling/freeze-btl',
  },
  SAMPLE_GENERATION: {
    FREEZED_SAMPLE: '/sampling/get-freeze/',
    GENERATED_SAMPLE: '/sampling/sample/',
    DOWNLOAD_SAMPLE: '/sampling/download/',
    CHANGE_SAMPLE: '/sampling/change-sample/',
  },
  IMPACT_ANALYSIS: {
    FINAL_TUNABLE: '/data/tunned/',
    UPDATE_TUNABLE_DATA: '/impact/update-thresholds',
    IMPACT_ANALYSIS: '/impact/impact-analysis',
    IMPACT_TABLE: '/impact/tasks/',
    IMPACT_FREEZE: '/impact/impact-analysis',
  },
  WHAT_IF_ANALYSIS: {
    WHAT_IF_ANALYSIS: '/impact/whatif-analysis',
  },
  DATA_MANAGER: {
    CREATE_FOLDER: '/data/folder',
    DELETE_FILE: '/data/file',
    DELETE_FOLDER: '/data/folder',
    UPLOAD_S3: '/data/dumpuploads',
    UPLOAD_SIGNED: '/data/dumpuploadsigned',
    UPLOAD_COMPLETED: '/data/dumpcomplete',
  },
  DOCUMENTATION: {
    TREE_DATA: '/documentation/services/',
    TEMPLATE_LIST: '/documentation/document-templates/',
    CREATE_TEMPLATE: '/documentation/document-templates/',
    DELETE_TEMPLATE: '/documentation/document-templates/',
    CREATE_DOC: '/documentation/documents/',
    DOCUMENT_LIST: '/documentation/documents/',
    DELETE_DOC: '/documentation/documents/',
    DOWNLOAD_DOC: '/documentation/documents/',
    VIEW_DOC: '/download-doc/',
  },

  SCENARIO_MANAGER: {
    SCENARIO_TEMPLATES: '/scenario_manager/scenarios/templates',
    SCENARIO_FIELDS: '/scenario_manager/scenarios/field',
    CREATE_SCENARIO: '/scenario_manager/scenarios',
    RUN_SCENARIO: '/scenario_manager/scenarios/run',
    GET_ALL_SCENARIOS: '/scenario_manager/scenarios/',
    GET_SCENARIO_RUN_ANOMALIES: '/scenario_manager/scenarios/anomalies/',
    GET_SCENARIO_RUNS_READ: '/scenario_manager/scenarios/runs/',
    GET_QUERY_BUILDER_TABLE_NAMES:
      '/scenario_manager/scenarios/query-builder/tables/',
    GET_QUERY_BUILDER_TABLE_DATA:
      '/scenario_manager/scenarios/query-builder/data/',
    GET_QUERY_BUILDER_SCHEMA:
      '/scenario_manager/scenarios/query-builder/schema/',

    GET_QUERY_BUILDER_COLUMN_DATA:
      '/scenario_manager/scenarios/query-builder/columns/',
    SCHEDULE_SCENARIO: '/scenario_manager/scenarios/schedule/',
    GET_DATSETS_OF_SCENARIO: '/scenario_manager/scenarios/allsets/',
    ATTRIBUTES: '/scenario_manager/scenarios/attribute/',
    SINGLE_ATTRIBUTE: '/scenario_manager/scenarios/single-attribute/',
    GET_FREQUENCY: '/scenario_manager/scenarios/frequency/',
    GET_ANAMOLIES: '/scenario_manager/scenarios/anomalies/',
    GET_RUN_RESULTS: '/scenario_manager/scenarios/run/',
    JUPYTER_NOTEBOOK: '/scenario_manager/scenarios/notebook/',
    SCENARIO_DUPLICATE: '/scenario_manager/scenarios/duplicate/',
    EDIT_SCENARIO_RUN_DATASET_NAME:
      '/scenario_manager/scenarios/edit-scenariorun-name/',
    COMMITS: '/scenario_manager/scenarios/commits/',
    VERSION: '/scenario_manager/scenarios/versions/',
    CHANGE_VERSION: '/scenario_manager/scenarios/change-version/',
    COMMIT_CHECKOUT: '/scenario_manager/scenarios/commit-checkout/',
    MIN_MAX_VALUES: '/scenario_manager/scenarios/min-max-value/',
    SCENARIO_RUN_COUNT: '/scenario_manager/scenarios/run-count/',
  },
  AUDITABILITY: {
    GET_AUDITABILITY_LOGS: '/tms-auth/audit-log/',
    GET_AUDITABILITY_DATA_DOWNLOAD: '/tms-auth/download-audit-log/',
    GET_TRANSACTION_LOGS: '/tunning/threshold-update-logs/',
  },
  COMMON: {
    DOWNLOAD_PDF: '/preliminary/download-pdf',
  },
  HISTORY: {
    GET_SCENARIO_HISTORY:
      '/scenario_manager/scenarios/scenario-update-history/',
  },
};
export default API_ENDPOINTS;
